<template>
  <img
    src="@/assets/index_icon.svg"
    class="icon"
    alt=""
  >
</template>

<script setup>

</script>

<style lang="scss" scoped>
.icon{
  width: 24px;
  height : 24px;
  margin-right: 10px;;
}
</style>
